<template>
    <v-card
        flat
        tile
        :loading="loading"
    >
        <w-simple-form
            :disabled="loading"
            :fields="formFields"
            :errors="errors"
            @submit="onSubmit"
        />
    </v-card>
</template>

<script>
import { required } from '@/utils/validation'
import { createFolder } from '@apps/media/api'
import uploadFormMixin from '@apps/media/components/MediaUploadForm/mixins/uploadFormMixin'

export default {
    name: 'CreateFolderForm',
    mixins: [ uploadFormMixin ],
    computed: {
        formFields() {
            return [
                {
                    type: 'v-text-field',
                    name: 'title',
                    props: {
                        label: this.$trans('Title'),
                        disabled: this.loading,
                        outlined: true,
                        dense: true,
                        rules: [
                            required
                        ]
                    }
                }
            ]
        }
    },
    methods: {
        async onSubmit(data) {
            this.loading = true

            try {
                const payload = {
                    ...data
                }

                if(this.parent) {
                    payload.parent = this.parent
                }

                const response = await createFolder(payload)

                this.$emit('success', { data: response.data.data })
            } catch (error) {
                this.errors = error.response &&
                    error.response.data &&
                    error.response.data.errors
                    ? error.response.data.errors
                    : {}

                this.$emit('error', { errors: this.errors })
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang=scss>

</style>
